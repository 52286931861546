@import "../../assets/variables/color.scss";

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;

  @media screen and (min-width: 575px) {
    border-radius: 24px;
  }
}

.before::before {
  display: none;
}

.resultPosterContainer {
  min-height: 55vh;
  background-color: $white;

  @media screen and (min-width: 575px) {
    min-height: auto;
  }
}

.resultPosterContainer::before {
  content: "";
  position: absolute;
  width: 32px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s;

  @media screen and (min-width: 575px) {
    display: none;
  }
}

.onScrollOption::before {
  background-color: rgba(0, 0, 0, 0.6);
  top: 8px;
  transition: all 0.2s;
}

.social {
  margin-top: 8px;
  display: flex;
  gap: 0 8px;
}

.share {
  height: 40px;
  width: 40px;
  background-color: #f0f0f0 !important;
  border-radius: 6px !important;

  &:hover {
    background-color: #f0f0f0 !important;
    opacity: 0.8;
  }
}

.icon {
  height: 40px;
  width: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & > circle {
    display: none;
  }

  & > path {
    display: none;
  }

  &:hover {
    opacity: 0.8;
  }
}

.facebook {
  background-image: url(../../../src/assets/images/social/social-facebook.svg);
}

.viber {
  background-image: url(../../../src/assets/images/social/social-viber.svg);
}

.whatsapp {
  background-image: url(../../../src/assets/images/social/social-whatsapp.svg);
}

.telegram {
  background-image: url(../../../src/assets/images/social/social-telegram.svg);
}

.buttonBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;

  button {
    margin: 0 16px;
  }
}

.outline {
  background-color: $white;
  border: 1px solid $accent;

  &:hover {
    background-color: $accent;
  }
}

.score {
  font-size: 32px;
  font-weight: 600;
}

.contentWrapper {
  margin: 0 16px;
}

.socialWrapper {
  padding-bottom: 24px;
}
